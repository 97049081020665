// Home.js
import React from 'react';
import strings from '../components/Language';

// const Home = () => {
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            language_image: localStorage.getItem("language_image"),
        }
    }

    changeLanguage(lang, language_image) {
        var self = this;
        if (lang) {
            strings.setLanguage(lang);
            localStorage.setItem("language", lang);
            localStorage.setItem("language_image", language_image);
            window.location.reload()
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <>
                <video autoPlay muted loop id="myVideo">
                    <source src="./assets/videos/e3952f02-c72d-4a04-b2b6-b01d9addcb57.mp4" type="video/mp4" />
                </video>

                <div class="main-back">
                    <div class="container px-4 px-lg-6 main-back">
                        <div class="row">
                            <div class="col-md-4 offset-md-4 col-sm-12">
                                <div class="row gx-4 gx-lg-5 mt-5 mb-5 align-items-center">
                                    <div class="header-title-style">
                                        <h2 class="font-weight-light">{strings["UTILITY ECOSYSTEM"]}</h2>

                                        <div className={"dropdown-lang-menu dropdown " + (this.state.menu_show ? 'show' : '')}
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                            onClick={() => this.setState({ menu_show: !this.state.menu_show })}>
                                            <img class="flag" src={this.state.language_image ? this.state.language_image : 'assets/images/flag.png'} alt="" />
                                            <a href="#" className="dropdown-toggle">{this.state.lang}</a>
                                            <ul className={"dropdown-menu " + (this.state.menu_show ? 'show' : '')}>
                                                <li><a onClick={() => this.changeLanguage("EN", "assets/images/flag.png")} className="dropdown-item" href="#"><img src="assets/images/flag.png" alt="" /> EN</a></li>
                                                <li><a onClick={() => this.changeLanguage("CN", "assets/images/cn.png")} className="dropdown-item" href="#"><img src="assets/images/cn.png" alt="" /> CN</a></li>
                                                <li><a onClick={() => this.changeLanguage("KR", "assets/images/kr.png")} className="dropdown-item" href="#"><img src="assets/images/kr.png" alt="" /> KR</a></li>
                                                <li><a onClick={() => this.changeLanguage("VN", "assets/images/vn.png")} className="dropdown-item" href="#"><img src="assets/images/vn.png" alt="" /> VN</a></li>
                                                <li><a onClick={() => this.changeLanguage("IN", "assets/images/in.png")} className="dropdown-item" href="#"><img src="assets/images/in.png" alt="" /> IN</a></li>
                                                <li><a onClick={() => this.changeLanguage("ES", "assets/images/es.png")} className="dropdown-item" href="#"><img src="assets/images/es.png" alt="" /> ES</a></li>
                                                <li><a onClick={() => this.changeLanguage("CZ", "assets/images/cz.png")} className="dropdown-item" href="#"><img src="assets/images/cz.png" alt="" /> CZ</a></li>
                                                <li><a onClick={() => this.changeLanguage("PT", "assets/images/pt.png")} className="dropdown-item" href="#"><img src="assets/images/pt.png" alt="" /> PT</a></li>
                                                <li><a onClick={() => this.changeLanguage("RU", "assets/images/ru.png")} className="dropdown-item" href="#"><img src="assets/images/ru.png" alt="" /> RU</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="row gx-4 gx-lg-5 header-menu">
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <a href={"https://app.futura-hub.io?language="+this.state.lang}>
                                                    <img src="./assets/images/01.png" />
                                                </a>
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["Futura <br /> Finance"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <img src="./assets/images/02.png" />
                                                <img class="menu-lock" src="./assets/images/lock.png" />
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["Futura <br /> Messaging"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <a href="https://www.edenswipe.com">
                                                    <img src="./assets/images/03.png" />
                                                </a>
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["Fiat <br /> Exchange"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <img src="./assets/images/04.png" />
                                                    <img class="menu-lock" src="./assets/images/lock.png" />
                                                </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["Futura <br /> Social"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <img src="./assets/images/05.png" />
                                                <img class="menu-lock" src="./assets/images/lock.png" />
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["Futura <br /> Game"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <img src="./assets/images/06.png" />
                                                <img class="menu-lock" src="./assets/images/lock.png" />
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["DEFI <br /> Market"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <img src="./assets/images/07.png" />
                                                <img class="menu-lock" src="./assets/images/lock.png" />
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["NFT <br /> Market"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <img src="./assets/images/08.png" />
                                                <img class="menu-lock" src="./assets/images/lock.png" />
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["FSC <br /> Gateway"] }} />
                                        </div>
                                    </div>
                                    <div class="col-4 mb-4">
                                        <div class="text-center">
                                            <div class="header-lock">
                                                <img src="./assets/images/09.png" />
                                                <img class="menu-lock" src="./assets/images/lock.png" />
                                            </div>
                                            <div class="title" dangerouslySetInnerHTML={{ __html: strings["FSC <br /> Ecommerce"] }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Home;
