const EN = {
    "UTILITY ECOSYSTEM": "UTILITY ECOSYSTEM",
    "Futura <br /> Finance": "Futura <br /> Finance",
    "Futura <br /> Messaging": "Futura <br /> Messaging",
    "Fiat <br /> Exchange": "Fiat <br /> Exchange",
    "Futura <br /> Social": "Futura <br /> Social",
    "Futura <br /> Game": "Futura <br /> Game",
    "DEFI <br /> Market": "DEFI <br /> Market",
    "NFT <br /> Market": "NFT <br /> Market",
    "FSC <br /> Gateway": "FSC <br /> Gateway",
    "FSC <br /> Ecommerce": "FSC <br /> Ecommerce",
};
export default EN;