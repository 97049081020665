const PT = {
    "UTILITY ECOSYSTEM": "ECOSSISTEMA UTILITÁRIO",
    "Private <br /> Placement": "Colocação <br /> Privada",
    "Futura <br /> Mobile App": "Futura <br /> Aplicativo móvel",
    "Digital <br /> Exchange": "Intercâmbio<br />Digital",
    "Cross Chain <br /> Bridge": "Corrente Cruzada <br /> Ponte",
    "FSC <br /> Swap": "Troca FSC<br />",
    "DEFI <br /> Market": "DEFI <br /> Mercado",
    "NFT <br /> Market": "Mercado NFT<br />",
    "FSC <br /> Gateway": "Portal FSC<br />",
    "FSC <br /> Ecommerce": "FSC <br /> Comércio eletrônico",
};
export default PT;