import CN from './CN';
import CZ from './CZ';
import EN from './EN';
import ES from './ES';
import IN from './IN';
import KR from './KR';
import PT from './PT';
import RU from './RU';
import VN from './VN';
import LocalizedStrings from 'react-localization';
let strings = new LocalizedStrings({
    CN: CN,
    CZ: CZ,
    EN: EN,
    ES: ES,
    IN: IN,
    KR: KR,
    PT: PT,
    RU: RU,
    VN: VN,
});

if (localStorage.getItem('language')) {
    strings.setLanguage(localStorage.getItem('language'));
}
else {
    localStorage.setItem("language", 'EN');
    strings.setLanguage('EN');
}

export default strings;