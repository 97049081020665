const RU = {
    "UTILITY ECOSYSTEM": "КОММУНАЛЬНАЯ ЭКОСИСТЕМА",
    "Private <br /> Placement": "Частное <br /> размещение",
    "Futura <br /> Mobile App": "Futura <br /> Мобильное приложение",
    "Digital <br /> Exchange": "Цифровая <br /> биржа",
    "Cross Chain <br /> Bridge": "Крест Цепной <br /> Мост",
    "FSC <br /> Swap": "FSC <br /> Обмен",
    "DEFI <br /> Market": "DEFI <br /> Рынок",
    "NFT <br /> Market": "NFT <br /> Рынок",
    "FSC <br /> Gateway": "FSC <br /> Шлюз",
    "FSC <br /> Ecommerce": "FSC <br /> Электронная коммерция",
};
export default RU;