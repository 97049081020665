const CN = {
    "UTILITY ECOSYSTEM": "应用生态",
    "Futura <br /> Finance": "Futura <br /> 金融",
    "Futura <br /> Messaging": "Futura <br /> 通讯",
    "Fiat <br /> Exchange": "法币 <br /> 兑换",
    "Futura <br /> Social": "Futura <br /> 社交",
    "Futura <br /> Game": "Futura <br /> 游戏",
    "DEFI <br /> Market": "DEFI <br /> 市场",
    "NFT <br /> Market": "NFT <br /> 市场",
    "FSC <br /> Gateway": "FSC <br /> 网关",
    "FSC <br /> Ecommerce": "FSC <br /> 电商"
};
export default CN;