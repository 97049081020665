const CZ = {
    "UTILITY ECOSYSTEM": "UTILITY ECOSYSTEM",
    "Futura <br /> Finance": "Futura <br /> Finance",
    "Futura <br /> Messaging": "Futura <br /> Messaging",
    "Fiat <br /> Exchange": "Fiat <br /> Exchange",
    "Futura <br /> Social": "Cross Chain <br /> Bridge",
    "Futura <br /> Game": "FSC <br /> Swap",
    "DEFI <br /> Market": "DEFI <br /> Market",
    "NFT <br /> Market": "NFT <br /> Market",
    "FSC <br /> Gateway": "FSC <br /> Gateway",
    "FSC <br /> Ecommerce": "FSC <br /> Ecommerce",
};
export default CZ;