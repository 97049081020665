const ES = {
    "UTILITY ECOSYSTEM": "ECOSISTEMA DE SERVICIOS PÚBLICOS",
    "Private <br /> Placement": "Colocación privada <br />",
    "Futura <br /> Mobile App": "Aplicación móvil Futura <br />",
    "Digital <br /> Exchange": "Intercambio <br /> digital",
    "Cross Chain <br /> Bridge": "Puente de cadena cruzada <br />",
    "FSC <br /> Swap": "FSC <br /> Intercambio",
    "DEFI <br /> Market": "DEFI <br /> Mercado",
    "NFT <br /> Market": "Mercado <br /> NFT",
    "FSC <br /> Gateway": "Puerta de enlace FSC <br />",
    "FSC <br /> Ecommerce": "FSC <br /> Comercio electrónico",
};
export default ES;