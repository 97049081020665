const IN = {
    "UTILITY ECOSYSTEM": "UTILITY ECOSYSTEM",
    "Private <br /> Placement": "Private <br /> Placement",
    "Futura <br /> Mobile App": "Futura <br /> Mobile App",
    "Digital <br /> Exchange": "Digital <br /> Exchange",
    "Cross Chain <br /> Bridge": "Cross Chain <br /> Bridge",
    "FSC <br /> Swap": "FSC <br /> Swap",
    "DEFI <br /> Market": "DEFI <br /> Market",
    "NFT <br /> Market": "NFT <br /> Market",
    "FSC <br /> Gateway": "FSC <br /> Gateway",
    "FSC <br /> Ecommerce": "FSC <br /> Ecommerce",
};
export default IN;