const VN = {
    "UTILITY ECOSYSTEM": "HỆ SINH THÁI TIỆN ÍCH",
    "Private <br /> Placement": "Vị trí riêng <br />",
    "Futura <br /> Mobile App": "Ứng dụng di động Futura <br />",
    "Digital <br /> Exchange": "Trao đổi kỹ thuật số <br />",
    "Cross Chain <br /> Bridge": "Cầu Xích <br />",
    "FSC <br /> Swap": "FSC <br /> Hoán đổi",
    "DEFI <br /> Market": "Thị trường DEFI <br />",
    "NFT <br /> Market": "Thị trường NFT <br />",
    "FSC <br /> Gateway": "Cổng FSC <br />",
    "FSC <br /> Ecommerce": "FSC <br /> Thương mại điện tử",
};
export default VN;