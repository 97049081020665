const KR = {
    "UTILITY ECOSYSTEM": "유틸리티 생태계",
    "Private <br /> Placement": "비공개 <br /> 게재위치",
    "Futura <br /> Mobile App": "Futura <br /> 모바일 앱",
    "Digital <br /> Exchange": "디지털 <br /> 교환",
    "Cross Chain <br /> Bridge": "크로스 체인 <br /> 브리지",
    "FSC <br /> Swap": "FSC <br /> 스왑",
    "DEFI <br /> Market": "DEFI <br /> 시장",
    "NFT <br /> Market": "NFT <br /> 시장",
    "FSC <br /> Gateway": "FSC <br /> 게이트웨이",
    "FSC <br /> Ecommerce": "FSC <br /> 전자상거래",
};
export default KR;